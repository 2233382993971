import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, CardDeck, Card } from "react-bootstrap"
import SignUpComponent from "../../components/sign-up"

const EventHackathonRecapPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        image="/images/pocam-white-bg.png"
        title="Beyond tropes, tokenism and talk"
        description="Beyond tropes, tokenism and talk Reimagining the strategist&#8217;s toolkit to be more inclusive of the BIPOC community Presented By: Tickets on sale now We are inviting agency strategists, researchers, creatives, media, account folks, etc to join us in a hackathon jointly organized by APG and POCAM to reimagine the planners toolkit &#8211; from the creative,&hellip;"
      />
      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="8">
              <h1 className="display-4 mb-3">Beyond tropes, tokenism and talk</h1>
              <h2 className="mb-0">
                Reimagining the strategist’s toolkit to be more inclusive of the BIPOC community
              </h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="10">
              <p className="lead">
                Presented By:
              </p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="3">
              <img className="px-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/APG-Canada-Logo-RGB.svg'} alt="AGP Canada logo"/>
            </Col>
            <Col md="3">
              <img className="px-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/pocam-logo-red.png'} alt="POCAM logo"/>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="8">
              <img className="" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/banner.png'} alt="banner"/>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="10">
              <p>
                On April 24th, over 150 industry professionals gathered together to meaningfully push the needle on diversity., equity and inclusion.
              </p>
              <p>
                Beyond Tropes, Tokenism and Talk, a BIPOC Hackathon jointly organized by the Account Planning Group of Canada (APG) and People of Colour in Advertising and Marketing (POCAM) sought to shift the BIPOC community beyond the casting call.
              </p>
              <p>
                Four teams, comprised of five individuals each, were tasked with designing a more inclusive brief in front of a live audience and panel of judges.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/key-takeaways.png'} alt="Key Takeaways"/>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h3 className="mb-4">Key Resources</h3>
              <h4>The Inclusive Marketer's Manifesto</h4>
              <p>An aspirational document, a moral compass, to ground us in the kinds of leaders we want to be.</p>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/inclusive-marketers-manifesto.png'} alt="The Inclusive Marketers Manifesto"/>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>The 6th "C" - Conscious Inclusion</h4>
              <p>A framework for conducting Discovery work. It includes a list of questions to consider exploring to be more consciously inclusive.</p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/6cs-inform-development.png'} alt="The 6C's to inform the development of the research brief"/>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>A Blueprint for Inclusive Briefs</h4>
              <p>The briefing process distilled into a blueprint, outlining how to change the conversations.</p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/blueprint-inclusive-briefs.png'} alt="A blueprint for inclusive briefs"/>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>Media Brief 2.0</h4>
              <p>A brief that puts a Brand DEI Statement, cultural context, and audience landscape front and centre.</p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/media-brief-2.png'} alt="Media Brief 2.0"/>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>The ASK</h4>
              <p>A briefing checklist tool</p>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/the-ask-01.png'} alt="The ASK - Slide 1"/>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/the-ask-02.png'} alt="The ASK - Slide 2"/>
            </Col>
          </Row>
          <Row className="mb-4 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/the-ask-03.png'} alt="The ASK - Slide 3"/>
            </Col>
          </Row>

          <Row className="mb-4 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/the-ask-04.png'} alt="The ASK - Slide 4"/>
            </Col>
          </Row>
      
          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/the-ask-05.png'} alt="The ASK - Slide 5"/>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h3 className="mb-4">The Presentations</h3>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/presentation-beyond-tropes-tokenism-and-talk.png'} alt="Beyond tropes, tokenism and talk"/>
            </Col>
            
            <Col md="8">
              <a
                className="btn btn-primary mt-3"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1zeimAMuhfIPJRA2Pfhlwv6zDdgbcj-IK/view?usp=sharing"
                target="_blank"
              >
                Download Creative Team #1 deck
              </a>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/presentation-hues-got-the-power.png'} alt="Hue's got the power?"/>
            </Col>
            
            <Col md="8">
              <a
                className="btn btn-primary mt-3"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1Sq8Kof9eHudvNu0KUPv5fFjnBoLO_uK1/view?usp=sharing"
                target="_blank"
              >
                Download Creative Team #2 deck
              </a>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/presentation-media-brief-2.png'} alt="Media brief 2.0"/>
            </Col>
            
            <Col md="8">
              <a
                className="btn btn-primary mt-3"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1rM-CD20C4xr-A8qeb8fkmpVCSsKq6bdE/view?usp=sharing"
                target="_blank"
              >
                Download Media Team deck
              </a>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="8">
              <img style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk-recap/presentation-hackathon.png'} alt="Hackathon"/>
            </Col>
            
            <Col md="8">
              <a
                className="btn btn-primary mt-3"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1NrrGmNiFPzotG7_1i8x-dSLhWzDLQwzp/view?usp=sharing"
                target="_blank"
              >
                Download Research Team deck
              </a>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h3 className="mb-4">Press Coverage</h3>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="10">
              <CardDeck>
                <Card>
                  <a href="https://strategyonline.ca/2024/04/25/apg-pocam-spark-discussion-on-more-inclusive-marketing-briefs/?utm_source=newsletter&utm_medium=email&utm_campaign=apg-pocam-spark-discussion-on-more-inclusive-marketing-briefs" rel="noreferrer" target="_blank">
                    <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/press-strategy.png" alt="Press - Strategy"/>
                  </a>
                </Card>

                <Card>
                  <a href="https://www.newcanadianmedia.ca/shaping-the-future-of-marketing-industry-leaders-rally-for-inclusivity-at-hackathon/?utm_source=NCM+-+General+Audience&utm_campaign=6a596edb5d-EMAIL_CAMPAIGN_2023_06_01_07_59_COPY_01&utm_medium=email&utm_term=0_-0cf063d419-%255BLIST_EMAIL_ID%25" rel="noreferrer" target="_blank">
                    <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/press-new-canadian-media.png" alt="Press - New Canadian Media"/>
                  </a>
                </Card>

                <Card>
                  <a href="https://www.campaigncanada.ca/article/1870620/media-led-idea-include-brand-dei-statements-briefing-stage-wins-pocam-x-apg-hackathon" rel="noreferrer" target="_blank">
                    <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/press-campaign.png" alt="Press - Campaign"/>
                  </a>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h3 className="mb-4">The Photos</h3>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-md-center">
            <Col md="10">
              <CardDeck>
                <Card>
                  <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/photo-01.jpeg" alt="The Participants, Mentors, Judges and Organizers"/>
                  <Card.Body>
                    <p>The Participants, Mentors, Judges and Organizers</p>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/photo-02.jpeg" alt="The Live Audience"/>
                  <Card.Body>
                    <p>The Live Audience</p>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img src="/images/beyond-tropes-tokenism-and-talk-recap/photo-03.jpeg" alt="The Winners"/>
                  <Card.Body>
                    <p>The Winners</p>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Container>
      </section>

      <SignUpComponent />
    </Layout>
  )
}

export default EventHackathonRecapPage